<template>
  <div class="changeUserName">
    <div class="cell">
      <van-field
        v-model="realName"
        maxlength="40"
        :placeholder="$t('seeting.changeName')"
        :rules="[{ required: true, message: $t('seeting.changeName') }]"
      />
    </div>
    <div class="button">
      <van-button size="large" @click="save" class="btn" color="#1AB370">{{
        $t("login.save")
      }}</van-button>
      <van-button
        size="large"
        class="btn bottomBtn"
        @click="back"
        type="default"
        >{{ $t("common.back") }}</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      realName: "",
    };
  },
  mounted() {
    this.realName = this.userInfo.realName;
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    go(path) {
      this.$router.push({ name: path });
    },
    save() {
      sdk.meetSetting.changeUserName(this.realName).then((res) => {
        this.$store.commit("user/setUserInfo", {
          ...this.userInfo,
          realName: this.realName,
        });
        this.back();
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.changeUserName {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;

  .cell {
    margin-top: 16px;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
    .bottomBtn {
      margin-top: 32px;
    }
  }

  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
    .box {
      display: flex;
      align-items: center;
    }
  }
}
</style>
